
import {useLocation} from 'react-router-dom'
import React, { useState, useEffect } from 'react';

import { ToggleLeft, ToggleRight } from 'lucide-react';
import MultiDeviceTimeSeriesChart from "./MultiDeviceTimeseriesChart" 

const getAlertStateStyles = (state) => {
  switch (state) {
    case 'crit':
      return {
        wrapper: 'bg-red-50',
        badge: 'bg-red-100 text-red-800',
        text: 'danger'
      };
    case 'warn':
      return {
        wrapper: 'bg-orange-50',
        badge: 'bg-orange-100 text-orange-800',
        text: 'warning'
      };
    case 'ok':
      return {
        wrapper: 'bg-green-50',
        badge: 'bg-green-100 text-green-800',
        text: 'ok'
      };
    default:
      return {
        wrapper: 'bg-gray-50',
        badge: 'bg-gray-100 text-gray-800',
        text: state
      };
  }
};

const Alert = ({ alert }) => {
  const styles = getAlertStateStyles(alert.alert_state);
  
  return (
    <tr className={styles.wrapper}>
      <td className="border px-4 py-2">{alert.id}</td>
      <td className="border px-4 py-2">{alert.device_id}</td>
      <td className="border px-4 py-2">
        {new Date(alert.timestamp).toLocaleString()}
      </td>
      <td className="border px-4 py-2">
        <span className={`px-2 py-1 rounded ${styles.badge}`}>
          {styles.text}
        </span>
      </td>
      <td className="border px-4 py-2">{alert.message}</td>
    </tr>
  );
};

const Machines = () => {
  const [machineName, setMachineName] = useState("");
  const [devicesName, setDevicesName] = useState("");
  const [showAlerts, setShowAlerts] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const [loc, setLoc] = useState("");
  const [orgName, setOrgName] = useState(""); // Replace with the appropriate initial value or fetch logic
const [item, setItem] = useState({ id: "" }); // Example, replace with actual data fetching


  


  
  const location = useLocation();
  const { deviceExternalIds, machineData, locationName } = location.state;

  useEffect(() => {
    const machineName = machineData.name;
    setMachineName(machineName);
   
    
    const deviceNames = machineData.devices.length > 0 
      ? machineData.devices.map(device => device.name).join(', ') 
      : "";
    setDevicesName(deviceNames);
    
    setLoc(locationName);
   // console.log(deviceNames)
  }, [location.state,locationName,machineData.devices,machineData.name]);

  const toggleAlerts = () => {
    setShowAlerts(!showAlerts);
  };

  useEffect(() => {
    const fetchAlerts = async () => {
      if (deviceExternalIds && deviceExternalIds.length > 0) {
        try {
          // Fetch alerts for each deviceID
          const alertPromises = deviceExternalIds.map((deviceId) =>
            fetch(`/api/alerts/${deviceId}`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
              },
            })
              .then((response) => {
                if (!response.ok) {
                  throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
              })
              .then((data) => {
                // Ensure that data is an array, and map it to add deviceId
                return Array.isArray(data)
                  ? data.map((alert) => ({
                      ...alert,
                      device_id: deviceId, // Add device_id to each alert
                    }))
                  : [];
              })
          );

          // Wait for all the fetches to finish and combine the results
          const alertsData = await Promise.all(alertPromises);
          // Flatten the array of alerts
          const allAlerts = alertsData.flat();
          setAlerts(allAlerts);
        } catch (e) {
          console.error('Error fetching alerts:', e);
          setAlerts([]);
        }
      }
    };

    if (showAlerts) {
      fetchAlerts();
      const interval = setInterval(fetchAlerts, 30000);
      return () => clearInterval(interval);
    }
  }, [deviceExternalIds, showAlerts]);

  return (
    <>
     
    <div className="flex   mb-4">
    <h1 className="text-sky-700" style={{
  fontWeight: 'bold',
  fontSize: '26px',
  textTransform: 'capitalize',
  letterSpacing: '0.5px',
  marginTop: '25px',
  marginLeft: '20px',
  '@media (max-width: 640px)': {
    fontSize: '18px', // Adjust the font size for mobile
  },
}}>
    {loc} &gt; {machineName}

  
  

</h1>
    
    


      <button
        className={`flex items-center space-x-2 px-4 py-2 rounded transition duration-300`}
        style={{color:'#000', marginRight:'1px',marginTop:'12px',   marginLeft: window.innerWidth <= 576 ? "2px" : "1100px",
          
        }}
        onClick={toggleAlerts}
      >
        {showAlerts ? (
          <ToggleRight className="h-9 w-9 text-green-500" 
          /> 
        ) : (
          <ToggleLeft className="h-9 w-9" /> 
        )}
        <span>Alerts</span>
      </button>
    </div>
    
    <p style={{
      fontWeight: 'bold',
      fontSize: '24px',
      color: '#2c3e50',
      textAlign:'center',
      textTransform: 'uppercase',
      letterSpacing: '1px',
     
    }}>
      Machines Chart
    </p> 



       

        {showAlerts ? (
          <div className="alerts-table-container mb-6 m-5">
            <h2 className="text-xl font-bold mb-4">Alerts</h2>
            <table className="w-full table-auto border-collapse border">
              <thead>
                <tr>
                  <th className="border px-4 py-2">ID</th>
                  <th className="border px-4 py-2">Device ID</th>
                  <th className="border px-4 py-2">Timestamp</th>
                  <th className="border px-4 py-2">State</th>
                  <th className="border px-4 py-2">Message</th>
                </tr>
              </thead>
              <tbody>
                {alerts.map(alert => (
                  <Alert key={alert.id} alert={alert} />
                ))}
              </tbody>
            </table>
          </div>
        ) :(<><MultiDeviceTimeSeriesChart allDevices={deviceExternalIds} devicesName={devicesName}/>
         </>) }
      

     
    </>
  );
};

export default Machines;

