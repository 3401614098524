import React, { useState, useEffect, useContext } from "react";
import "../styles/Header.css"; 
import { LogOutIcon, Bell,Menu,XIcon} from "lucide-react"; 
import { logout } from "./utils/api";
import { DeviceContext } from '../components/contexts/DeviceContext'; 

function Header({toggleSidenav,isSidenavVisible}) {
  const { toggleAlerts } = useContext(DeviceContext);
  const [organization, setOrganization] = useState("");  
    
  useEffect(() => {
    const token = localStorage.getItem('token');

    fetch("/api/organization", {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Cache-Control': 'no-cache'
      }
    })
      .then((response) => {
        if (!response.ok) throw new Error("Failed to fetch organization name");
        return response.json();
      })
      .then((data) => setOrganization(data.organization))
      .catch((error) => console.error("Error fetching organization:", error));
  }, []);

  return (
    <header className="bg-grey-600 p-3 text-black flex justify-between shadow-md items-center" >
      <div className="flex items-center w-full">
         <button
                  className="lg:hidden p-2"
                  onClick={toggleSidenav}
                >
                  {isSidenavVisible ? (
                    <XIcon size={24} /> // Replace with your X icon component
                  ) : (
                    <Menu size={24} /> // Replace with your Menu icon component
                  )}
                </button>
        <h1 className="text-2xl  md:text-2xl text-base sm:text-xl font-bold text-blue-600  mt-4 flex-grow">{organization}</h1>
        </div>
      <div className="flex items-center space-x-4 ml-auto">
          <button
            className="flex items-center space-x-2 bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 logout transition duration-300"
            onClick={logout}
          >
            <LogOutIcon className="h-5 w-5" />
            <span>Logout</span>
          </button>
          </div>
    </header>


  );
}
export default Header