import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/Login.css'


const Login = () => {
  const [credentials, setCredentials] = useState({
    username: '',
    password: ''
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await fetch('/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials),
      });

      if (!response.ok) {
        throw new Error('Invalid credentials');
      }

      const data = await response.json();
      localStorage.setItem('token', data.token); 
      localStorage.setItem('email', credentials.username)
      navigate('/');
    } catch (err) {
      setError('Login failed. Please check your credentials.');
    }
  };

  const handleGoogleLogin = () => {
    // Get the OAuth URL from environment variables
    const googleAuthUrl = process.env.REACT_APP_GOOGLE_AUTH_URL;
    if (!googleAuthUrl) {
      setError('Google login is not properly configured');
      return;
    }
    window.location.href = googleAuthUrl;
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center items-center p-4">
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-gray-900">MotionMetrics</h1>
      </div>
      
      <div className="w-full max-w-md bg-white rounded-lg shadow-md overflow-hidden">
        <div className="p-6">
          <div className="text-center mb-6">
            <h2 className="text-2xl font-semibold text-gray-800">
              Sign in to your account
            </h2>
            <p className="text-gray-600 mt-2">
              Enter your credentials to access your account
            </p>
          </div>
          
          {error && (
            <div className="mb-4 p-3 bg-red-50 border border-red-200 text-red-600 rounded-md">
              {error}
            </div>
          )}
          
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <input
                type="email"
                name="username"
                placeholder="Email address"
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-colors"
                value={credentials.username}
                onChange={handleChange}
                required
              />
            </div>

            <div>
              <input
                type="password"
                name="password"
                placeholder="Password"
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-colors"
                value={credentials.password}
                onChange={handleChange}
                required
              />
            </div>

            <button 
              type="submit" 
              className="w-full py-2 px-4 border border-transparent rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors"
            >
              Sign in
            </button>
          </form>

          <div className="mt-6 text-center text-sm">
            <button 
              onClick={handleGoogleLogin}
              className="w-full py-2 px-4 border border-transparent rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-colors"
            >
              Login with Google
            </button>
          </div>
          
          {/*<div className="mt-6 text-center text-sm">
            <button 
              onClick={() => {/* Add forgot password handler *
              className="text-indigo-600 hover:text-indigo-500 transition-colors"
            >
              Forgot your password?
            </button>
          </div>*/}
        </div>
      </div>
    </div>
  );
};

export default Login;