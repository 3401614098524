import React, { useState, useEffect, useContext, useCallback } from 'react';
import {  useParams, useLocation } from 'react-router-dom';
import { DeviceContext } from "../components/contexts/DeviceContext";
import MetricCard from './MetricCard';
import { ToggleLeft, ToggleRight } from 'lucide-react';
import PrometheusTimeSeriesChart from './TimeSeriesChart';
import "../styles/Speedometer.css";

const getAlertStateStyles = (state) => {
  switch (state) {
    case 'crit':
      return {
        wrapper: 'bg-red-50',
        badge: 'bg-red-100 text-red-800',
        text: 'danger'
      };
    case 'warn':
      return {
        wrapper: 'bg-orange-50',
        badge: 'bg-orange-100 text-orange-800',
        text: 'warning'
      };
    case 'ok':
      return {
        wrapper: 'bg-green-50',
        badge: 'bg-green-100 text-green-800',
        text: 'ok'
      };
    default:
      return {
        wrapper: 'bg-gray-50',
        badge: 'bg-gray-100 text-gray-800',
        text: state
      };
  }
};

const Alert = ({ alert }) => {
  const styles = getAlertStateStyles(alert.alert_state);
  
  return (
    <tr className={styles.wrapper}>
      <td className="border px-4 py-2">{alert.id}</td>
      <td className="border px-4 py-2">{alert.device_id}</td>
      <td className="border px-4 py-2">
        {new Date(alert.timestamp).toLocaleString()}
      </td>
      <td className="border px-4 py-2">
        <span className={`px-2 py-1 rounded ${styles.badge}`}>
          {styles.text}
        </span>
      </td>
      <td className="border px-4 py-2">{alert.message}</td>
    </tr>
  );
};


const Dashboard = () => {
  const { deviceId } = useParams();
  const { showAlerts, toggleAlerts } = useContext(DeviceContext);
  const location = useLocation(); 
  const {InfoForHeading,label} = location.state || {};
 
  const [metrics, setMetrics] = useState([]);
  const [HeadLoc, setHeadLoc] = useState(""); 
  const [HeadMachine, setHeadMachine] = useState(""); 
  const [labelSet,setLabel] = useState("")
  //eslint-disable-next-line
  const [timeSeriesData, setTimeSeriesData] = useState([]);
  const [deviceInfo, setDeviceInfo] = useState(null);
  const [alerts, setAlerts] = useState([]);
  //eslint-disable-next-line
  const [aggregationInterval, setAggregationInterval] = useState('10m');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const today = new Date();
  const defaultStartDateTime = new Date(today.setHours(0, 0, 0, 0)).toISOString();
  const defaultEndDateTime = new Date().toISOString();
  
   //eslint-disable-next-line
  const [startDateTime, setStartDateTime] = useState(defaultStartDateTime);
   //eslint-disable-next-line
  const [endDateTime, setEndDateTime] = useState(defaultEndDateTime);


  

  // Function to sort metrics based on the fixed order
  const sortMetrics = useCallback((metrics) => {
    const metricOrder = [
      "Temperature (degC)",
      "Axial RMS Acceleration (m/s2)",
      "Horizontal RMS Acceleration (m/s2)",
      "Vertical RMS Acceleration (m/s2)",
      "Noise (db)",
      "Axial RMS velocity (mm/sec)",
      "Horizontal RMS velocity (mm/sec)",
      "Vertical RMS velocity (mm/sec)",
  
    ];
    return metrics.sort((a, b) => {
      const indexA = metricOrder.indexOf(a.title);
      const indexB = metricOrder.indexOf(b.title);
      return indexA - indexB;
    });
  },[]);

  useEffect(() => {
    if (InfoForHeading) {
      const loc = InfoForHeading.block_name;
      const labelNew = label;
      const machine = InfoForHeading.machines[0]?.name; // Access the correct machine dynamically
  
      setHeadLoc(loc);
      setHeadMachine(machine);
      setLabel(labelNew);
    }

  }, [InfoForHeading, label]); // Ensure the effect runs when InfoForHeading or label changes  


  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const token = localStorage.getItem('token');
    
        const dashboardResponse = await fetch(`/api/dashboard/${deviceId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Cache-Control': 'no-cache'
          }
        });
    
        if (!dashboardResponse.ok) throw new Error(`HTTP error! status: ${dashboardResponse.status}`);
        
        const data = await dashboardResponse.json();
      
        const sortedMetrics = sortMetrics(data.metrics);
        setMetrics(sortedMetrics);
        setDeviceInfo(data.deviceInfo);
    
        const timeseriesResponse = await fetch(`/api/dashboard/${deviceId}/timeseries`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Cache-Control': 'no-cache'
          }
        });
    
        const timeseriesData = await timeseriesResponse.json();
    
        if (Array.isArray(timeseriesData) && timeseriesData.length > 0) {
          setTimeSeriesData(timeseriesData); 
         
        } else {
          //console.log("Received empty timeseries data:", timeseriesData);
        }
    
        setLoading(false);
      } catch (e) {
        console.error("Error fetching dashboard data:", e);
        setError("Failed to load dashboard data. Please try again later.");
        setLoading(false);
      }
    };

    fetchDashboardData();
    const interval = setInterval(fetchDashboardData, 30000);
    return () => clearInterval(interval);
  }, [deviceId, startDateTime, endDateTime, aggregationInterval,sortMetrics]);

  useEffect(() => {
    const fetchAlerts = async () => {
      if (showAlerts && deviceId) {
        try {   
          const response = await fetch(`/api/alerts/${deviceId}`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json'
            }
          });
  
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
  
          const data = await response.json();
  
          if (Array.isArray(data)) {
            setAlerts(data.map(alert => ({
              id: alert.id,
              device_id: alert.device_id,
              timestamp: new Date(alert.timestamp),
              alert_state: alert.alert_state,
              message: alert.message
            })));
          } else {
            console.warn(" alerts data format:", data);
            setAlerts([]);
          }
        } catch (e) {
          console.error("Error fetching alerts:", e);
          setAlerts([]);
        }
      }
    };
  
    if (showAlerts) {
      fetchAlerts();
      const interval = setInterval(fetchAlerts, 30000);
      return () => clearInterval(interval);
    }
  }, [showAlerts, deviceId]);

  if (loading) return <div className="p-6">Loading...</div>;
  if (error) return <div className="p-6 text-red-500">{error}</div>;
  

  return (
    <div className="dashboard-container"> 
      <div className="althem"
      style={{
        position: 'sticky', // Sticky positioning
        top:0,
        backgroundColor: 'white', // Prevents overlapping with content
        zIndex: 1000, // Ensures it stays above other elements
        
      }}
      >
        <h1 className="text-xs font-bold text-sky-700 alt"
        >{HeadLoc} &gt; {HeadMachine} &gt; {labelSet}</h1>
        <button
          className={`flex items-center space-x-2 px-4 py-2 rounded transition duration-300 alerts-button mt-0 
          `}
          onClick={toggleAlerts}
        >
          {showAlerts ? (
            <ToggleRight className="h-9 w-9 text-green-500" /> 
          ) : (
            <ToggleLeft className="h-9 w-9" /> 
          )}
          <span>Alerts</span>
        </button>
      </div>

      {deviceInfo && (
  <div className="device-info-banner  bg-opacity-80  z-50  ml-1 "   >
    <p className="text-white  "> 
      Device ID: {deviceInfo.mac}
    </p>
  </div>
)
}

      {showAlerts ? (
        <div className="alerts-table-container mb-6">
          <h2 className="text-xl font-bold mb-4">Alerts</h2>
          <table className="w-full table-auto border-collapse border">
            <thead>
              <tr>
                <th className="border px-4 py-2">ID</th>
                <th className="border px-4 py-2">Device ID</th>
                <th className="border px-4 py-2">Timestamp</th>
                <th className="border px-4 py-2">State</th>
                <th className="border px-4 py-2">Message</th>
              </tr>
            </thead>
            <tbody>
              {alerts.map(alert => (
                <Alert key={alert.id} alert={alert} />
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <>
          <div className="metrics-grid mb-6 line-metric mt-6">
          {metrics && Array.isArray(metrics) && metrics.slice(0, 4).map((metric, index) => (

              <MetricCard 
                key={index}
                title={metric.title}
                value={metric.value}
                max={metric.max} 
              />
            ))}
          </div>

          <PrometheusTimeSeriesChart deviceId={deviceId} />
        </>
      )}
    </div>
  );
};

export default Dashboard;