import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import '../styles/Machine.css';
import { Card, Section } from './Cards';
import { FaBell } from 'react-icons/fa'; // If needed

const Home = () => {
  const location = useLocation();
  const [devicesCount, setDevicesCount] = useState(null);
  const [statusCritical, setStatusCritical] = useState(null);
  const [statusWarning, setStatusWarning] = useState(null);
  const [statusIdle, setStatusIdle] = useState(null);
  const [loading,setLoading] = useState(null);
  const [error, setError] = useState(null);
  const [disconnected,setDisconnected] = useState(null);
  

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const response = await fetch("/api/devices/status_count",{
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`, // Use token from localStorage
            'Content-Type': 'application/json',
          },
        }); // Fetch the data
        if (!response.ok) {
          throw new Error(`Error fetching status: ${response.statusText}`); // Handle non-2xx responses
        }
        const data = await response.json(); // Await the JSON conversion
        setStatusCritical(data.critical_devices ?? 0);
        setStatusWarning(data.warning_devices ?? 0);
        setStatusIdle(data.idle_devices ?? 0);
        setDisconnected(data.disconnected ?? 0);
      } catch (err) {
        setError(err.message); // Set error if any
      } finally {
        setLoading(false); // Stop the loading state
      }
    };
  
    fetchStatus();
  }, []);
  
  useEffect(() => {
    // Access the state from location.state
    if (location.state) {
      const { organizationId } = location.state;
      fetchDevicesCount()
    }
  }, [location]);  
   

  const fetchDevicesCount = async (locationId) => {
   
      const { organizationId } = location.state;
      const response = await fetch(`/api/home/devices/${organizationId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`, // Use token from localStorage
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      if (response.ok) {
        setDevicesCount(data.device_count); // Set devices count if the API response is successful  
      } 
    
  };
  
  return (
    <div className="App">
      <div className="headerContainer">
        <h2 className="subheader">Real-Time Device Status</h2>
      </div>

      <Section title="Real-Time Device Status">
    <div className="card-container">
      <Card title="Devices" text={<span className="text-blue-600">DEVICES</span>} value={devicesCount} />
      <Card title="Critical" text={<span className="text-red-600">CRITICAL</span>}  />
      <Card title="Warning" text={<span className="text-yellow-600">WARNING</span>}  />
      <Card title="Idle" text={<span className="text-green-600">IDLE</span>}  />
      <Card title="Disconnected" text={<span className="text-gray-600">DISCONNECTED</span>}  />
    </div>
  </Section>
     {/* 
      <header className="header">Auto Diagnostics and Recommendations(ADR)</header>
      <Section title="Auto Diagnostics and Recommendations (ADR)">
        <div className="card-container">
          <Card title="Assets" text="ASSESTS" value="13" />
          <Card title="Highest Risk" status="HIGHEST RISK" progress={36} />
          <Card title="High Risk" status="HIGH RISK" progress={0} />
     0     <Card title="Medium Risk" status="MEDIUM RISK" progress={20} />
          <Card title="Low Risk" status="LOW RISK" progress={50} />
          <Card title="No FFT" status="NO FFT" progress={80} />
        </div>
      </Section>
      */}
    </div>
  );
};

export default Home;
