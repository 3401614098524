import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChevronDown, ChevronRight, AlertTriangle, Plus, Trash2 ,XIcon,Menu} from 'lucide-react';
import AddItemForm from './AddItemForm';
import { fetchWithAuth } from './utils/api';
import "../styles/Speedometer.css";
import "../styles/SideNav.css";

const NestedItem = ({ item, level = 0, onAdd, onDelete, userRole, setDevicesCount, activeItem }) => {
  const [isOpen, setIsOpen] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  const isActive = useMemo(() => {
    if (level === 0) {
      if (item.label === "Home") {
        return location.pathname === "/";
      }
      return location.pathname.includes(`/location/`) && location.state?.BlockId === item.id;
    }
    
    if (level === 1) {
      return location.pathname === "/machines" && location.state?.deviceExternalIds?.some(id => 
        item.children?.some(device => device.device_external_id === id)
      );
    }
    
    if (level === 2) {
      return location.pathname.includes(`/dashboard/${item.device_external_id}`);
    }

    return false;
  }, [level, item, location]);

  const getLevelStyles = () => {
    const baseStyles = "transition-all duration-200";
    
    if (level === 0) {
      return `${baseStyles} ${
        isActive 
          ? 'bg-sky-100 shadow-sm border-l-4 border-sky-600 font-semibold' 
          : 'hover:bg-sky-50 hover:border-l-4 hover:border-sky-300'
      }`;
    }
    
    if (level === 1) {
      return `${baseStyles} ${
        isActive 
          ? 'bg-gray-100 shadow-sm border-l-4 border-gray-600' 
          : 'hover:bg-gray-50 hover:border-l-4 hover:border-gray-300'
      }`;
    }
    
    return `${baseStyles} ${
      isActive 
        ? 'bg-indigo-50 shadow-sm border-l-4 border-indigo-400' 
        : 'hover:bg-indigo-50/30 hover:border-l-4 hover:border-indigo-200'
    }`;
  };

  const getTextStyles = () => {
    if (isActive) {
      if (level === 0) return 'text-sky-900 font-semibold';
      if (level === 1) return 'text-gray-900 font-semibold';
      return 'text-indigo-900 font-semibold';
    }
    
    if (level === 0) return 'text-sky-700 group-hover:text-sky-900';
    if (level === 1) return 'text-gray-700 group-hover:text-gray-900';
    return 'text-indigo-700 group-hover:text-indigo-900';
  };
  
  // Existing fetchDirect function and useEffect remain unchanged...
  const fetchDirect =useCallback( async () => {
    const response = await fetch(`/api/hierarchy`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    const organization = data[0];
    const organizationId = organization.organization_id;
    navigate(`/`, {
      state: { organizationId: organizationId },
    });
  },[navigate])

  useEffect(() => {
    if (window.location.pathname === '/') {
      fetchDirect();
    }
},[fetchDirect]);

  // All existing handleItemClick logic remains unchanged...
  const handleItemClick = async (e) => {
    e.stopPropagation();

    // All your existing handleItemClick logic here...
    if (level === 0 && item.label === "Home") {
      const response = await fetch(`/api/hierarchy`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      const organization = data[0];
      const organizationId = organization.organization_id;
      navigate(`/`, {
        state: { organizationId: organizationId },
      });
    } else if (level === 0 && item.label !== "Home") {
      // Your existing level 0 logic...
      const getOrg = async () => {
        try {
          const orgNameResponse = await fetch("/api/organization", {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json',
            },
          });

          const orgRes = await orgNameResponse.json();
          const orgName = orgRes.organization;

          const hierarchyResponse = await fetch("/api/hierarchy", {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json',
            },
          });

          const hierarchyData = await hierarchyResponse.json(); 
          const clickedBlock = hierarchyData.find(block => block.id === item.id) ; 
          if(!clickedBlock){
            console.error("Block not found for the clicked item"); 
            return;
          }
          //console.log(clickedBlock.block_name)

          
          const organization = hierarchyData[0];
          const organizationId = organization.organization_id;

          navigate(`/location/${orgName}/${item.id}`, {
            state: { BlockId: item.id, orgName: organization, organizationId: organizationId, blockName:clickedBlock.block_name },
          });

        } catch (error) {
          console.error("Error fetching organization or hierarchy:", error);
        }
      };

      getOrg();
    } else if (level === 1) {
      // Your existing level 1 logic...
      try {
        const hierarchyResponse = await fetch("/api/hierarchy", {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        });

        const hierarchyData = await hierarchyResponse.json();
        const location = hierarchyData.find(location =>
          location.machines.some(machine => machine.id === item.id) 
        ); 
        //console.log(location)

        if (!location) {
          console.error('Machine not found in hierarchy data');
          return;
        }

        const machine = location.machines.find(machine => machine.id === item.id); 
        //console.log(machine)

        if (!machine) {
          console.error('Machine not found for item.id:', item.id);
          return;
        }

        const deviceExternalIds = machine.devices.map(device => device.device_external_id);

        navigate("/machines", {
          state: {
            deviceExternalIds: deviceExternalIds,
            machineData : machine,
            locationName: location.block_name
          },
        });

      } catch (error) {
        console.error('Error fetching hierarchy or machine data:', error);
      }
    } else if (level === 2 && item.device_external_id) {
      // Your existing level 2 logic...
      try {
        const hierarchyResponse = await fetch("/api/hierarchy", {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        });

        const hierarchyData = await hierarchyResponse.json();
        //console.log(hierarchyData)

        let selectedBlock = null;
        let selectedMachine = null;

        for (const block of hierarchyData) {
          const machine = block.machines.find(machine =>
            machine.devices.some(device => device.device_external_id === item.device_external_id)
          );

          if (machine) {
            selectedBlock = block;
            selectedMachine = machine;
            //console.log(machine)
            break; 
            
          }
        }

        if (selectedBlock && selectedMachine) {
          navigate(`/dashboard/${item.device_external_id}`, {
            state: {
              InfoForHeading: {
                block_name: selectedBlock.block_name,
                machines: [selectedMachine],
              },
              label: item.label,
            },
          });
        }

      } catch (error) {
        console.error('Error fetching hierarchy or machine data:', error);
      }
    }

    setIsOpen(!isOpen);
  };

  


  const getItemColor = () => {
    if (item.isWarning) return 'text-orange-500';
    return isActive ? 'text-sky-900 font-bold' : 'text-gray-700';
  };

  return (
    <>
    <ul className={`${level > 0 ? "ml-4" : ""} mb-1`}>
    <div
        className={`
          flex items-center justify-between group py-2 px-2
          cursor-pointer rounded-r-lg
          ${getLevelStyles()}
        `}
        onClick={handleItemClick}
        role="button"
        tabIndex={0}
      >
        <div className="flex items-center flex-grow">
          {item.children && (
            <span className={`${getItemColor()} transition-all duration-200 mr-1`}>
              {isOpen ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
            </span>
          )}

          {!item.children && <span className="w-4 mr-1" />}

          {item.isWarning && (
            <AlertTriangle size={16} className="mr-1 text-orange-500" />
          )}

<span className={`${getTextStyles()} transition-all duration-200 select-none`}>
            {item.label}
          </span>
        </div>

        <div className="opacity-0 group-hover:opacity-100 flex items-center space-x-2 md:flew-row-col">
          {/* All your existing action buttons code remains unchanged... */}
          {level === 0 && item.label !== "Home" && (
            <>
              {userRole === "editor" && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    onAdd("machine", item);
                  }}
                  className="hover:text-sky-300 p-1"
                  title="Add Machine"
                >
                  <Plus size={16} />
                </button>
              )}
              {userRole === "editor" && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    onDelete("location", item);
                  }}
                  className="hover:text-red-400 p-1"
                  title="Delete Location"
                >
                  <Trash2 size={16} />
                </button>
              )}
            </>
          )}

          {level === 1 && userRole === "editor" && (
            <>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onAdd("device", item);
                }}
                className="hover:text-sky-300 p-1"
                title="Add Device"
              >
                <Plus size={16} />
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete("machine", item);
                }}
                className="hover:text-red-400 p-1"
                title="Delete Machine"
              >
                <Trash2 size={16} />
              </button>
            </>
          )}

          {level === 2 && userRole === "editor" && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                onDelete("device", item);
              }}
              className="hover:text-red-400 p-1"
              title="Delete Device"
            >
              <Trash2 size={16} />
            </button>
          )}
        </div>
      </div>

      {isOpen && item.children && (
        <>
        <li className="mt-1">
          {item.children.map((child, index) => (
            <NestedItem
              key={index}
              item={child}
              level={level + 1}
              onAdd={onAdd}
              userRole={userRole}
              onDelete={onDelete}
              setDevicesCount={setDevicesCount}
              activeItem={activeItem}
            />
          ))}
        </li>
       
        </>
      )}
    </ul>
    </>
  );
};

const SideNav = ({ userRole, isSidenavVisible, onResize, defaultWidth = 240 }) => {
  const [hierarchy, setHierarchy] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  //eslint-disable-next-line
  const [formType, setFormType] = useState('');
   //eslint-disable-next-line
  const [parentItem, setParentItem] = useState(null);
  const [selectedType, setSelectedType] = useState("");
  const [selectedParent, setSelectedParent] = useState(null);
   //eslint-disable-next-line
  const [devicesCount, setDevicesCount] = useState(null);
   //eslint-disable-next-line
  const [width, setWidth] = useState(defaultWidth);
  const isDraggingRef = useRef(false);

  //const sideNavRef = useRef(null);
  const startXRef = useRef(0);
  const startWidthRef = useRef(0);
  const [isCollapsed, setIsCollapsed] = useState(false);  // State to track whether the sidebar is collapsed

  const navigate = useNavigate();


  const handleToggleCollapse = () => {
    setIsCollapsed(prevState => !prevState);  // Toggle the collapsed state
  };


  /*const handleMouseDown = (e) => {
    if (e.target === sideNavRef.current) {
      isDraggingRef.current = true;
      startXRef.current = e.clientX;
      startWidthRef.current = width;
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }
  };*/

  const handleMouseMove = useCallback((e) => {
    if (!isDraggingRef.current) return;
    const deltaX = e.clientX - startXRef.current;
    const newWidth = Math.min(Math.max(256, startWidthRef.current + deltaX), 512);
    setWidth(newWidth);
    onResize?.(newWidth);
  }, [onResize]);

  const handleMouseUp = useCallback(() => {
    isDraggingRef.current = false;
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  }, [handleMouseMove]);

  useEffect(() => {
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseMove, handleMouseUp]);

  useEffect(() => {
    setWidth(defaultWidth);
  }, [defaultWidth]);

  const fetchHierarchy = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchWithAuth('/api/hierarchy');
      if (!response.ok) throw new Error('Failed to fetch hierarchy');
      const data = await response.json();

      if (!Array.isArray(data)) {
        console.error('Invalid data format received:', data);
        throw new Error('Invalid data format received from API');
      }

      const formattedHierarchy = data.map(location => ({
        label: location.block_name,
        id: location.id,
        organization_id: location.organization_id,
        isActive: location.is_active,
        children: location.machines?.map(machine => ({
          label: machine.name,
          id: machine.id,
          organization_id: machine.organization_id,
          isActive: machine.is_active,
          children: machine.devices?.map(device => ({
            label: device.name,
            id: device.id,
            organization_id: device.organization_id,
            device_external_id: device.device_external_id,
            to: `/location/${device.device_external_id}`,
            status: device.status,
            isActive: device.is_active,
          })) || []
        })) || []
      }));

      setHierarchy(formattedHierarchy);
    } catch (error) {
      console.error('Error fetching hierarchy:', error);
      if (error.message === 'Unauthorized') navigate('/login');
    } finally {
      setIsLoading(false);
    }
  }, [navigate]);

  useEffect(() => {
    fetchHierarchy();
  }, [fetchHierarchy]);

  const handleAdd = async (type, parentItem, customItem = null) => {
    if (customItem) {
      let endpoint = '';
      let body = {};

      switch (type) {
        case 'location':
          endpoint = '/api/location';
          body = { block_name: customItem.block_name, name: customItem.name, description: customItem.description };
          break;
        case 'machine':
          endpoint = '/api/machine';
          body = { name: customItem.name, location_id: parentItem.id, description: customItem.description };
          break;
        case 'device':
          endpoint = '/api/device';
          body = { name: customItem.name, machine_id: parentItem.id, device_external_id: customItem.device_external_id, type: customItem.type, status: 'active' };
          break;
        default:
          console.error('Invalid type:', type);
          return;
      }

      try {
        const response = await fetchWithAuth(endpoint, { method: 'POST', body: JSON.stringify(body) });
        if (!response.ok) throw new Error('Failed to add item');
        await fetchHierarchy();
      } catch (error) {
        console.error('Error adding item:', error);
        alert('Failed to add item');
      }
      return;
    }

    setFormType(type);
    setParentItem(parentItem);
    setShowForm(true);
    setSelectedType(type);
    setSelectedParent(parentItem);
  };

  const handleDelete = async (type, item) => {
    let endpoint = '';
    switch (type) {
      case 'location':
        endpoint = `/api/location/${item.id}`;
        break;
      case 'machine':
        endpoint = `/api/machine/${item.id}`;
        break;
      case 'device':
        endpoint = `/api/device/${item.id}`;
        break;
      default:
        return;
    }

    try {
      const response = await fetchWithAuth(endpoint, { method: 'DELETE' });
      if (!response.ok) throw new Error('Failed to delete item');
      await fetchHierarchy();
    } catch (error) {
      console.error('Error deleting item:', error);
      alert('Failed to delete item');
    }
  };

  const handleCloseForm = () => {
    setShowForm(false);
    setFormType('');
    setParentItem(null);
  };

  if (isLoading) {
    return <div className="bg-white w-64 min-h-screen p-4 flex items-center justify-center"><div className="text-gray-500">Loading...</div></div>;
  }

  return (
   
    <nav
    className={`transition-all side-nav-overlay bg-white min-h-screen p-4 border-r ${
      isSidenavVisible
        ? isCollapsed
          ? 'w-20' // Collapsed on large screens, full width on smaller screens
          : 'w-64'
        : 'hidden'
    } duration-300`}
  >
      <div className="flex flex-col h-full">
        <button onClick={handleToggleCollapse} className="p-2 hidden lg:block">
          {isCollapsed ? (
            <Menu size={24} />  // Menu icon for collapsed state
          ) : (
            <XIcon size={24} style={{marginLeft:'12rem'}}/>  // X icon for expanded state
          )}
        </button>
      
      {/* Sidebar content */}
      {!isCollapsed && (  // Only render the content when the sidebar is expanded
          <>
            <ul className="space-y-2">
              <li className="flex items-center space-x-2">
                <NestedItem item={{ label: "Home", to: "/" }} userRole={userRole} />
                {userRole === 'editor' && (
                  <button
                    onClick={() => handleAdd('location', null)}
                    className="hover:text-sky-300 p-1"
                    title="Add Location"
                  >
                    <Plus size={20} />
                  </button>
                )}
              </li>
              {hierarchy.map((item, index) => (
                <NestedItem
                  key={index}
                  item={item}
                  level={0}
                  onAdd={handleAdd}
                  userRole={userRole}
                  onDelete={handleDelete}
                  setDevicesCount={setDevicesCount}
                />
              ))}
            </ul>
            {userRole === 'editor' && showForm && (
              <AddItemForm
                type={selectedType}
                parentItem={selectedParent}
                onClose={handleCloseForm}
                onAdd={handleAdd}
              />
            )}
          </>
        )}
      </div>
    </nav>
  );
};


export default SideNav;