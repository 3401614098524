import React from 'react';
import "../styles/Speedometer.css";

const Speedometer = ({ value,step=10 , max=100}) => {
  const minValue = 0;

 const clampedValueForangle=Math.max(0,Math.min(value,max));
 const angle = ((clampedValueForangle - minValue) / (max - minValue)) * 180 - 90;

    // Initialize classValue
    let classValue = '';

    // Determine class for border and text color based on clamped value within max
    if (value < (max / 3)) {
      classValue = "text-green borders";
    } else if (value >= (max / 3) && value <= (2 * max) / 3) {
      classValue = "text-orange borders";
    } else {
      classValue = "text-red borders";
    }

  // Inline style for the needle rotation
  const needleStyle = {
    transform: `translateX(-50%) rotate(${angle}deg)`, // Translate to center and rotate
    transformOrigin: 'bottom center', // Ensure rotation happens from the bottom center
  }; 


  const labels = [];
  const labelRadius = 70;
  for (let i = minValue; i <= max; i += step) {
    const angle = ((i - minValue) / (max - minValue)) * 180 - 180;
    const x = 100 + labelRadius * Math.cos((angle * Math.PI) / 180);
    const y = 100 + labelRadius * Math.sin((angle * Math.PI) / 180);
    labels.push(
      <div
        key={i}
        style={{
          position: "absolute",
          left: `${x}px`,
          top: `${y}px`,
          transform: "translate(-50%, -50%)",
          fontSize: "12px",
          color: "#000",
          padding: "2px",
          margin: "-2px"
        }}
      >
        {i}
      </div>
    );
  }

  // Generate ticks around the speedometer
  const ticks = [];
  const tickRadius = 100;
  const tickLength = 14;
  for (let i = minValue; i <= max; i += step) {
    const tickangle = ((i - minValue) / (max - minValue)) * 180 - 180;
    const x1 = 100 + tickRadius * Math.cos((tickangle * Math.PI) / 180);
    const y1 = 100 + tickRadius * Math.sin((tickangle * Math.PI) / 180);
    const x2 = 100 + (tickRadius - tickLength) * Math.cos((tickangle * Math.PI) / 180);
    const y2 = 100 + (tickRadius - tickLength) * Math.sin((tickangle * Math.PI) / 180);

    ticks.push(
      <line
        key={`tick-${i}`}
        x1={x1}
        y1={y1}
        x2={x2}
        y2={y2}
        stroke="#000"
        strokeWidth="2"
        style={{ position: "absolute" }}
      />
    );
  }

  return (
    <div className="entire">
      <div className="speedometer">
        <svg width="200" height="200" style={{ position: "absolute", top: 0, left: 0 }}>
          {ticks} {/* Render ticks inside the SVG */}
        </svg>
        {labels} {/* Render labels */}
        <div className="needle" style={needleStyle}></div>
        <div className="needle-dot"></div>
      </div>
      <div className={`value-cont mt-0 pt-0 ${classValue} transition-transform transform hover:scale-110 font-bold-500`}>
        <p>{value}</p>
      </div>
    </div>
  );
};

export default Speedometer;





  


  



  
  