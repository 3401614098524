import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useParams, Link } from 'react-router-dom';
import '../styles/Machine.css';
import { Card, Section } from './Cards';

const Location = () => {
  const { locationId } = useParams();
  const locationState = useLocation().state;

  const location = useLocation();
  const { organizationId,blockName } = location.state || {}; 

  //console.log(blockName)

  // State Management
  const [alerts, setAlerts] = useState([]);
  const [devices, setDevices] = useState([]); 
  // eslint-disable-next-line no-unused-vars
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [devicesCount, setDevicesCount] = useState(locationState?.devicesCount || 0);
  const [statusCounts, setStatusCounts] = useState({
    critical: 0,
    warning: 0,
    idle: 0,
    disconnected: 0,
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [view, setView] = useState('alerts'); // Default to show alerts
  const [selectedStatus, setSelectedStatus] = useState(null);

  // Fetch status counts
  useEffect(() => {
    const fetchStatusCounts = async () => {
      try {
        const response = await fetch('/api/devices/status_count', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`Error fetching status: ${response.statusText}`);
        }

        const data = await response.json();
        //console.log(data)

        setStatusCounts({
          critical: data.critical_devices || 0,
          warning: data.warning_devices || 0,
          idle: data.idle_devices || 0 ,
          disconnected: data.disconnected || 0, // Make sure disconnected count is valid
        });
      } catch (err) {
        setError(err.message);
        console.error('Error fetching status counts:', err);
      }
    };

    fetchStatusCounts();
  }, []);



  const getAlertStateStyles = (state) => {
    switch (state?.toLowerCase()) {
      case 'crit':
        return {
          wrapper: 'bg-red-50',
          badge: 'bg-red-100 text-red-800',
          text: 'Critical'
        };
      case 'warn':
        return {
          wrapper: 'bg-orange-50',
          badge: 'bg-orange-100 text-orange-800',
          text: 'Warning'
        };
      case 'ok':
        return {
          wrapper: 'bg-green-50',
          badge: 'bg-green-100 text-green-800',
          text: 'OK'
        };
      default:
        return {
          wrapper: 'bg-gray-50',
          badge: 'bg-gray-100 text-gray-800',
          text: state || 'Unknown'
        };
    }
  };
  // eslint-disable-next-line no-unused-vars
  const styles = getAlertStateStyles(alert.alert_state);
  // Fetch alerts
  useEffect(() => {
    const fetchAlerts = async () => {

      if (!locationId || !organizationId) return;

      try {
        setLoading(true);


        const response = await fetch(`/api/locations/${organizationId}/${locationId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        setDevicesCount(data.device_count);

        // Update alerts
        setAlerts(data.alerts || []);
      } catch (err) {
        setError(err.message);
        console.error('Error fetching alerts:', err);
        setAlerts([]);
      } finally {
        setLoading(false);
      }
    };

    fetchAlerts();
  }, [locationId, organizationId]);

  // Fetch devices
  const fetchDevices =useCallback( async (status = null) => {
    try {
      const response = await fetch(`/api/devices?location_id=${locationId}${status ? `&status=${status}` : ''}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`Error fetching devices: ${response.statusText}`);
      }

      const deviceData = await response.json();
      //console.log(deviceData);

      setDevices(deviceData);
      setFilteredDevices(deviceData); // Update filtered devices with the fetched data
    } catch (err) {
      setError(err.message);
      //console.error('Error fetching devices:', err);
    }
  },[locationId]);

  // Handle card clicks
  const handleViewChange = (newView, status = null) => {
    setView(newView);
    setSelectedStatus(status);
    if (newView === 'devices') {
      fetchDevices(status);
    }
  };
  
  
  useEffect(() => {
    if (selectedStatus) {
        
      fetchDevices(selectedStatus); // Fetch devices when status is selected
    }
  }, [selectedStatus,fetchDevices]);
  
  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-lg">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-lg text-red-500">Error: {error}</div>
      </div>
    );
  }

  return (
    <div className="App">
       <h1 className="text-sky-700" style={{
      fontWeight: 'bold',
      fontSize: '26px',
      textTransform: 'capitalize',
      letterSpacing: '0.5px',
      marginTop: '25px',
      marginLeft: '20px',
      whiteSpace: 'nowrap', // Prevent line breaks
     
    }}>{blockName}</h1>
      <div className="headerContainer">
       
        <h2 className="subheader">Real-Time Device Status</h2>
      </div>

      <Section title="Real-Time Device Status">
        <div className="card-container">
          <Card
            text={<span className="text-blue-600">DEVICES</span>}
            value={devicesCount}
            onClick={() => handleViewChange('devices')}
            style={{ cursor: 'pointer' }}
          />

          <Card title="Critical" text={<span className="text-red-600">CRITICAL</span>} value={statusCounts.critical} onClick={() => handleViewChange('critical')}
            style={{ cursor: 'pointer' }} />
          <Card title="Warning" text={<span className="text-yellow-600">WARNING</span>} value={statusCounts.warning} onClick={() => handleViewChange( 'warning')}
            style={{ cursor: 'pointer' }} />
          <Card title="Idle" text={<span className="text-green-600">IDLE</span>} value={statusCounts.idle} onClick={() => handleViewChange( 'idle')}
            style={{ cursor: 'pointer' }} />
          <Card
            title="Disconnected"
            text={<span className="text-gray-600">DISCONNECTED</span>}
            value={statusCounts.disconnected}
            onClick={() => handleViewChange('disconnected')}
          />
          <Card
            title="Alerts"
            text={<span className="text-red-600">ALERTS</span>}
            value={alerts.length}
            onClick={() => handleViewChange('alerts')}
            style={{ cursor: 'pointer' }}
          />

        </div>

      </Section>

      <div className="dashboard-container align-them" style={{ overflow: 'auto' }}>
        {view === 'alerts' ? (
          alerts.length > 0 ? (
            <div className="alerts-table-container mb-6">
  

              <h2 className="text-xl font-bold mb-4">Alerts</h2>
              <table className="w-full table-auto border-collapse border">
                <thead>
                  <tr>
                    <th className="border px-4 py-2">ID</th>
                    <th className="border px-4 py-2">Device ID</th>
                    <th className="border px-4 py-2">Timestamp</th>
                    <th className="border px-4 py-2">State</th>
                    <th className="border px-4 py-2">Message</th>
                  </tr>
                </thead>
                <tbody>
                  {alerts.map((alert) => {
                    const styles = getAlertStateStyles(alert.alert_state);
                    return (
                      <tr key={`${alert.id}-${alert.timestamp}`} className={styles.wrapper}>
                        <td className="border px-4 py-2">{alert.id}</td>
                        <td className="border px-4 py-2">{alert.device_id}</td>
                        <td className="border px-4 py-2">
                          {new Date(alert.timestamp).toLocaleString()}
                        </td>
                        <td className="border px-4 py-2">
                          <span className={`px-2 py-1 rounded ${styles.badge}`}>
                            {styles.text}
                          </span>
                        </td>
                        <td className="border px-4 py-2">{alert.message}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

          ) : (
            <div className="text-center py-4">
              <p className="text-gray-500">No alerts found</p>
            </div>
          )
        ) : (
          <div>
            
            {devices.length > 0 ? (
              <table className="w-full table-auto border-collapse border">
                <thead>
                  <tr>
                    <th className="border px-4 py-2">ID</th>
                    <th className="border px-4 py-2">Name</th>
                    <th className="border px-4 py-2">Type</th>
                    <th className="border px-4 py-2">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {devices.map((device) => (
                    <tr key={`${device.id}-${device.device_external_id}`}>
                      <td className="border px-4 py-2">
                        <Link
                          to={`/dashboard/${device.device_external_id}`}
                          className="text-blue-600 hover:underline"
                        >
                          {device.device_external_id}
                        </Link>
                      </td>
                      <td className="border px-4 py-2">{device.name}</td>
                      <td className="border px-4 py-2">{device.type}</td>
                      <td className="border px-4 py-2">{device.status}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="text-center py-4">
                <p className="text-gray-500">No devices found</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Location;