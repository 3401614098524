import React, { useState, useEffect, useCallback } from "react";
import ApexCharts from "react-apexcharts";
import { RefreshCw } from "lucide-react";

const MultiDeviceTimeSeriesChart = ({ allDevices, devicesName }) => {
  const [timeRange, setTimeRange] = useState("1h");
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [customDateRange, setCustomDateRange] = useState(false);
  const [startDateTime, setStartDateTime] = useState("");
  const [deviceNames, setDeviceNames] = useState({}); 
  const [endDateTime, setEndDateTime] = useState("");
  const [selectedMetric, setSelectedMetric] = useState("temperature");

 

  const colors = [
    "#8884d8", "#82ca9d", "#ffc658", "#ff7300", 
    "#a4de6c", "#d0ed57", "#83a6ed"
  ];

  const metrics = [
    {
      id: "axial_velocity",
      name: "Axial Velocity",
      color: "#8884d8",
      unit: "mm/s",
    },
    {
      id: "horizontal_velocity",
      name: "Horizontal Velocity",
      color: "#82ca9d",
      unit: "mm/s",
    },
    {
      id: "vertical_velocity",
      name: "Vertical Velocity",
      color: "#ffc658",
      unit: "mm/s",
    },
    {
      id: "temperature",
      name: "Temperature",
      color: "#ff7300",
      unit: "°C",
    },
    {
      id: "axial_acceleration",
      name: "Axial Acceleration",
      color: "#a4de6c",
      unit: "m/s²",
    },
    {
      id: "horizontal_acceleration",
      name: "Horizontal Acceleration",
      color: "#d0ed57",
      unit: "m/s²",
    },
    {
      id: "vertical_acceleration",
      name: "Vertical Acceleration",
      color: "#83a6ed",
      unit: "m/s²",
    },
  ];

  const timeRanges = [
    { value: "5m", label: "5m" },
    { value: "15m", label: "15m" },
    { value: "1h", label: "1h" },
    { value: "6h", label: "6h" },
    { value: "12h", label: "12h" },
    { value: "24h", label: "24h" },
    { value: "7d", label: "7d" },
  ];

  useEffect(() => {
    const end = new Date();
    let start;
    

    if (!customDateRange) {
      const range = timeRange.match(/\d+/)?.[0];
      const unit = timeRange.match(/[a-zA-Z]+/)?.[0];
      if (range && unit) {
        start = new Date(
          end - range * (unit === "m" ? 60000 : unit === "h" ? 3600000 : 86400000)
        );
      }
    }

    setEndDateTime(end.toISOString().slice(0, 16));
    if (start) {
      setStartDateTime(start.toISOString().slice(0, 16));
    }
  }, [timeRange, customDateRange]);

  const calculateInterval = (startDate, endDate) => {
    const diffHours = (endDate - startDate) / 3600000;
    if (diffHours <= 1) return '1m';
    if (diffHours <= 6) return '5m';
    if (diffHours <= 24) return '15m';
    if (diffHours <= 72) return '30m';
    if (diffHours <= 168) return '1h';
    return '2h';
  };

  const fetchFallbackData = async (device, token) => {
    const fallbackStart = new Date(Date.now() - 3600000);
    const fallbackEnd = new Date();
    const fallbackInterval = "1m";
    const fallbackQueryParams = new URLSearchParams({
      start: fallbackStart.toISOString(),
      end: fallbackEnd.toISOString(),
      interval: fallbackInterval,
    });

    const fallbackResponse = await fetch(
      `/api/dashboard/${device}/timeseriesNew?${fallbackQueryParams}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Cache-Control": "no-cache",
        },
      }
    );

    if (!fallbackResponse.ok) {
      throw new Error(`HTTP error! status: ${fallbackResponse.status}`);
    }

    const fallbackData = await fallbackResponse.json();
    if (!fallbackData || !Array.isArray(fallbackData) || fallbackData.length === 0) {
      throw new Error("No fallback data available");
    }

    return fallbackData;
  };

  const fetchData = useCallback(async (customStart, customEnd) => {
    if (!allDevices || !Array.isArray(allDevices)) {
      setError("No devices provided");
      return;
    }

    try {
      setError(null);
      const token = localStorage.getItem("token");
      if (!token) throw new Error("No authentication token found");

      let start, end, interval;

      if (customStart && customEnd) {
        start = new Date(customStart);
        end = new Date(customEnd);
      } else if (customDateRange && startDateTime && endDateTime) {
        start = new Date(startDateTime);
        end = new Date(endDateTime);
      } else {
        const range = timeRange.match(/\d+/)[0];
        const unit = timeRange.match(/[a-zA-Z]+/)[0];
        end = new Date();
        start = new Date(
          end - range * (unit === "m" ? 60000 : unit === "h" ? 3600000 : 86400000)
        );
      }

      interval = calculateInterval(start, end);
      const queryParams = new URLSearchParams({
        start: start.toISOString(),
        end: end.toISOString(),
        interval,
      });

      const devicesData = await Promise.all(
        allDevices.map(async (device) => {
          try {
            const response = await fetch(
              `/api/dashboard/${device}/timeseriesNew?${queryParams}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Cache-Control": "no-cache",
                },
              }
            );

            if (!response.ok) {
              try {
                const fallbackData = await fetchFallbackData(device, token);
                return { device, data: fallbackData };
              } catch (fallbackError) {
                console.error(`No data available for device ${device}`);
                return { device, data: null };
              }
            }

            const jsonData = await response.json(); 
           
            if (!jsonData || !Array.isArray(jsonData) || jsonData.length === 0) {
              try {
                const fallbackData = await fetchFallbackData(device, token);
                return { device, data: fallbackData };
              } catch (fallbackError) {
                //console.error(`No data available for device ${device}`);
                return { device, data: null };
              }
            }

            return { device, data: jsonData };
          } catch (error) {
            console.error(`Error fetching data for device ${device}:`, error);
            try {
              const fallbackData = await fetchFallbackData(device, token);
              return { device, data: fallbackData };
            } catch (fallbackError) {
              return { device, data: null };
            }
          }
        })
      );

      const formattedData = {};
      const newDeviceNames = {};
      devicesData.forEach(({ device, data }) => {
        if (data && Array.isArray(data) && data.length > 0) {
          formattedData[device] = data;
          newDeviceNames[device] = data[0]?.device_name || device; 
        }
      });

      if (Object.keys(formattedData).length === 0) {
        setError("No data available for any devices");
        setData({});
      } else {
        setData(formattedData);
        setError(null);
        setDeviceNames(newDeviceNames); 
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(error.message);
    }
  }, [allDevices, timeRange, customDateRange, startDateTime, endDateTime]);


  const handleTimeRangeChange = (value) => {
    if (value === "custom") {
      setCustomDateRange(true);
    } else {
      setCustomDateRange(false);
      setTimeRange(value);
    }
  };

  const handleMetricChange = (metricId) => {
    setSelectedMetric(metricId);
  };

  useEffect(() => {
    fetchData();
    const interval = autoRefresh && !customDateRange ? setInterval(fetchData, 30000) : null;
    return () => interval && clearInterval(interval);
  }, [fetchData, autoRefresh, customDateRange]);

  const chartData = allDevices.map((device, index) => ({
    name: `${deviceNames[device]} - ${metrics.find(metric => metric.id === selectedMetric).name}`,
    data: data[device]
      ? data[device]
          .map(item => ({
            x: new Date(item.timestamp),
            y: item[selectedMetric]
          }))
          .filter(point => point.y !== null)
      : [],
    color: colors[index % colors.length],
  }));

  const chartOptions = {
    chart: {
      type: "line",
      zoom: {
        enabled: true,
        type: "x",
      },
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    xaxis: {
      type: "datetime",
      labels: {
        formatter: function (value) {
          const date = new Date(value);
          return date.toLocaleString();
        },
      },
    },
    yaxis: {
      title: {
        text: metrics.find(m => m.id === selectedMetric).unit,
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        let tooltipHtml = `<div class="bg-green-600 p-2 rounded-t text-white">
          ${new Date(w.globals.seriesX[seriesIndex][dataPointIndex]).toLocaleString()}
        </div>`;

        series.forEach((s, idx) => {
          const value = s[dataPointIndex]; 
          const deviceId = allDevices[idx]; 
          const deviceName = deviceNames[deviceId] || deviceId; 

          if (value !== null && value !== undefined) {
            tooltipHtml += `<div class="p-2 bg-white">
              <span style="color: ${colors[idx % colors.length]}">${deviceName}:</span>
              ${value} ${metrics.find(m => m.id === selectedMetric).unit}
            </div>`;
          }
        });

        return tooltipHtml;
      },
    },
    legend: {
      position: "bottom",
    },
    colors: chartData.map(series => series.color),
  };

  return (
    <div className="p-5">
      <div className="flex flex-col space-y-4 mb-6">
        <div className="flex justify-between items-center space-x-4 mb-5">
          <div className="flex space-x-2">
            {timeRanges.map((range) => (
              <button
                key={range.value}
                onClick={() => handleTimeRangeChange(range.value)}
                className={`px-4 py-2 rounded-md text-sm font-medium transition-colors
                  ${timeRange === range.value 
                    ? 'bg-blue-500 text-white' 
                    : 'bg-gray-100 text-gray-600 hover:bg-gray-200'}`}
              >
                {range.label}
              </button>
            ))}
            <button
              onClick={() => handleTimeRangeChange('custom')}
              className={`px-4 py-2 rounded-md text-sm font-medium transition-colors
                ${customDateRange 
                  ? 'bg-blue-500 text-white' 
                  : 'bg-gray-100 text-gray-600 hover:bg-gray-200'}`}
            >
              Custom
            </button>
          </div>

          {customDateRange && (
            <div className="flex gap-4 items-center">
              <input
                type="datetime-local"
                value={startDateTime}
                onChange={(e) => setStartDateTime(e.target.value)}
                className="p-2 border rounded-md"
              />
              <input
                type="datetime-local"
                value={endDateTime}
                onChange={(e) => setEndDateTime(e.target.value)}
                className="p-2 border rounded-md"
              />
              <button
                onClick={() => fetchData()}
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              >
                Apply
              </button>
            </div>
          )}

          <div>
            <button
              onClick={() => setAutoRefresh(!autoRefresh)}
              className={`flex items-center gap-2 px-3 py-1.5 rounded-md text-sm
                ${autoRefresh 
                  ? 'bg-green-100 text-green-700' 
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'}`}
              disabled={customDateRange}
            >
              <RefreshCw className={`h-4 w-4 ${autoRefresh ? 'animate-spin' : ''}`} />
              {autoRefresh ? 'Auto-refresh ON' : 'Auto-refresh OFF'}
            </button>
          </div>
        </div>

        <div className="flex flex-wrap gap-3">
          {metrics.map((metric) => (
            <button
              key={metric.id}
              onClick={() => handleMetricChange(metric.id)}
              className={`px-4 py-2 rounded-md text-sm font-medium transition-colors
                ${selectedMetric === metric.id 
                  ? 'bg-blue-500 text-white' 
                  : 'bg-gray-100 text-gray-600 hover:bg-gray-200'}`}
            >
              {metric.name}
            </button>
          ))}
        </div>
      </div>

      <div className="mb-10">
        {error ? (
          <div className="text-red-500 bg-red-50 px-6 py-3 rounded-md border border-red-200 text-center mb-4">
            {error}
          </div>
        ) : (
          <ApexCharts
            options={chartOptions}
            series={chartData}
            type="line"
            height={500}
          />
        )}
      </div>
    </div>
  );
};

export default MultiDeviceTimeSeriesChart