
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import SideNav from './components/SideNav';
import MachineForm from './components/MachineForm';
import Login from './components/Login';
import Header from "./components/Header"
import Home from './components/Home';
import Dashboard from './components/Dashboard';
import OAuthCallback from './components/OAuthCallback';
import Location from "./components/Location"

import { DeviceProvider } from './components/contexts/DeviceContext';


import './styles/layout.css';
import Machines from './components/Machines';

// Layout component that includes the SideNav
const Layout = ({ children,userRole,toggleSidenav , isSidenavVisible,sidenavWidth,handleSidenavResize}) => (
  <>
  <Header 
  toggleSidenav={toggleSidenav}
  isSidenavVisible={isSidenavVisible} // Pass this to Header

  />
  <div className="flex h-screen  bg-gray-100">

    <SideNav userRole={userRole} isSidenavVisible={isSidenavVisible} onResize={handleSidenavResize} defaultWidth={sidenavWidth}/>
    {/*<div className="flex-1 overflow-y-auto">*/}
    <div className={`flex-1 overflow-scroll ${isSidenavVisible ? 'dimmed' : ''}`}>
      {children}
    </div>
  </div>
  </>
);

// Enhanced Protected Route component with token validation
const PrivateRoute = ({ children }) => {

  const [userRole, setUserRole] = useState("");
  const [sidenavWidth, setSidenavWidth] = useState(240); // Default width of SideNav
  const [isSidenavVisible,setIsSidenavVisible] = useState(true);

  const handleSidenavResize = (newWidth) => {
    setSidenavWidth(newWidth);
  }; 

  useEffect(() => {
    const verifyToken = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        window.location.href = '/login';
        return;
      }

      try {
        // Validate token format
        const parts = token.split('.');
        if (parts.length !== 3) {
          throw new Error('Invalid token format');
        }

        // Check token expiration
        const payload = JSON.parse(atob(parts[1]));
        if (payload.exp && payload.exp * 1000 < Date.now()) {
          throw new Error('Token expired');
        }

        // Verify token with backend
        const response = await fetch('/api/organization', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Cache-Control': 'no-cache'
          }
        });

        if (!response.ok) {
          throw new Error('Token validation failed');
        }
      } catch (error) {
        console.error('Token verification failed:', error);
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
    };

    const fetchUserRole = async() =>{
      const email = localStorage.getItem('email');
    
      try{
        const roleRes = await fetch(`/api/users?email=${email}`); 
       
        if(roleRes.ok){
          const userData = await roleRes.json();
          if(userData.role){
            setUserRole(userData.role)
          }else{
            console.error('Failed to fetch user role', roleRes.statusText)
          }
        }else {
          console.error('Failed to fetch user role:', roleRes.statusText);
        }
      }catch(error){
        console.error("Error fetching user role", error)
      }
    }
  
    verifyToken();
    fetchUserRole();
  }, []); // Empty dependency array means this runs once on mount

  const token = localStorage.getItem('token');
  if (!token) {
    return <Navigate to="/login" replace />;
  } 

  const toggleSidenav = () => {
    setIsSidenavVisible((prev) => !prev);
  };

  return <Layout userRole={userRole} toggleSidenav={toggleSidenav} isSidenavVisible={isSidenavVisible} sidenavWidth={sidenavWidth} handleSidenavResize={handleSidenavResize}>{children}</Layout>;
};

// Auth Check for public routes
const PublicRoute = ({ children }) => {
  const token = localStorage.getItem('token');
  
  if (token) {
    try {
      const parts = token.split('.');
      if (parts.length === 3) {
        const payload = JSON.parse(atob(parts[1]));
        if (payload.exp && payload.exp * 1000 > Date.now()) {
          return <Navigate to="/" replace />;
        }
      }
    } catch (error) {
      localStorage.removeItem('token');
    }
  }

  return children;
};

const App = () => {
  return (
    <DeviceProvider>
    <Router>
      <Routes>
        {/* Public routes with authentication check */}
        <Route 
          path="/login" 
          element={
            <PublicRoute>
              <div className="h-screen">
                <Login />
              </div>
            </PublicRoute>
          }
        />
        
        {/* OAuth Callback route */}
        <Route
          path="/oauth/callback"
          element={
            <div className="h-screen">
              <OAuthCallback />
            </div>
          }
        />

        {/* Protected routes */}
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />
        <Route
          path="/form"
          element={
            <PrivateRoute>
              <MachineForm />
            </PrivateRoute>
          }
        />
        <Route
          path='/dashboard/:deviceId'
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />  
         <Route
          path='/location/:orgName/:locationId'
          element={
            <PrivateRoute>
              <Location />
            </PrivateRoute>
          }
        />   
        
        <Route 
            path="/api/machine" 
            element={
              <PrivateRoute>
                <MachineForm />
              </PrivateRoute>
            }
          />

        <Route path="/machines" element ={
          <PrivateRoute><Machines/></PrivateRoute>
        }/>
        {/* Catch all route - redirect to home */}
        <Route
          path="*"
          element={
            <Navigate to="/" replace />
          }
        />
      </Routes>
    </Router>
    </DeviceProvider>
  );
};

export default App;