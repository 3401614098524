import React, { useState, useEffect, useCallback } from "react";
import ApexCharts from "react-apexcharts";
import { RefreshCw } from "lucide-react";

const TimeSeriesChart = ({ deviceId }) => {
  const [timeRange, setTimeRange] = useState("1h");
  const [data, setData] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [customDateRange, setCustomDateRange] = useState(false);
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  // const [isRefreshing, setIsRefreshing] = useState(false);
  
  // const [zoomedRange, setZoomedRange] = useState(null);

  const [selectedMetrics, setSelectedMetrics] = useState({
    axial_velocity: true,
    horizontal_velocity: true,
    vertical_velocity: true,
    temperature: false,
    // noise: false,
    axial_acceleration: false,
    horizontal_acceleration: false,
    vertical_acceleration: false,
  });

  const metrics = [
    {
      id: "axial_velocity",
      name: "Axial Velocity",
      nameColor: "#8884d8",
      color: "#8884d8",
      unit: "mm/s",
    },
    {
      id: "horizontal_velocity",
      name: "Horizontal Velocity",
      nameColor: "#82ca9d",
      color: "#82ca9d",
      unit: "mm/s",
    },
    {
      id: "vertical_velocity",
      name: "Vertical Velocity",
      nameColor: "#ffc658",
      color: "#ffc658",
      unit: "mm/s",
    },
    {
      id: "temperature",
      name: "Temperature",
      nameColor: "#ff7300",
      color: "#ff7300",
      unit: "°C",
    },
    {
      id: "axial_acceleration",
      name: "Axial Acceleration",
      nameColor: "#a4de6c",
      color: "#a4de6c",
      unit: "m/s²",
    },
    {
      id: "horizontal_acceleration",
      name: "Horizontal Acceleration",
      nameColor: "#d0ed57",
      color: "#d0ed57",
      unit: "m/s²",
    },
    {
      id: "vertical_acceleration",
      name: "Vertical Acceleration",
      nameColor: "#83a6ed",
      color: "#83a6ed",
      unit: "m/s²",
    },
  ];
  
  const timeRanges = [
    { value: "5m", label: "5m", interval: "10s" },
    { value: "15m", label: "15m", interval: "30s" },
    { value: "1h", label: "1h", interval: "1m" },
    { value: "6h", label: "6h", interval: "5m" },
    { value: "12h", label: "12h", interval: "10m" },
    { value: "24h", label: "24h", interval: "30m" },
    { value: "7d", label: "7d", interval: "2h" },
    { value: "custom", label: "Custom", interval: "5m" },
  ];

  useEffect(() => {
    const end = new Date();
    let start;

    if (!customDateRange) {
      const range = timeRange.match(/\d+/)?.[0];
      const unit = timeRange.match(/[a-zA-Z]+/)?.[0];
      if (range && unit) {
        start = new Date(
          end -
            range * (unit === "m" ? 60000 : unit === "h" ? 3600000 : 86400000)
        );
      }
    }

    setEndDateTime(end.toISOString().slice(0, 16));
    if (start) {
      setStartDateTime(start.toISOString().slice(0, 16));
    }
  }, [timeRange, customDateRange]);

  const calculateInterval = (startDate, endDate) => {
    const diffHours = (endDate - startDate) / 3600000;
    if (diffHours <= 1) return '1m';
    if (diffHours <= 6) return '5m';
    if (diffHours <= 24) return '15m';
    if (diffHours <= 72) return '30m';
    if (diffHours <= 168) return '1h';
    return '2h';
  };
  const validateDateRange = (start, end) => {
    if (start >= end) {
      return "Start date must be before end date";
    }
    return null;
  };

  const fetchData = useCallback(
    async (customStart, customEnd) => {
      if (!deviceId) {
        setError("No device ID provided");
        return;
      }

      try {
        setError(null);
        // setIsRefreshing(true);

        const token = localStorage.getItem("token");
        if (!token) throw new Error("No authentication token found");

        let start, end, interval;

        if (customStart && customEnd) {
          start = new Date(customStart);
          end = new Date(customEnd);
        } else if (customDateRange && startDateTime && endDateTime) {
          start = new Date(startDateTime);
          end = new Date(endDateTime);
        } else {
          const range = timeRange.match(/\d+/)[0];
          const unit = timeRange.match(/[a-zA-Z]+/)[0];
          end = new Date();
          start = new Date(
            end -
              range * (unit === "m" ? 60000 : unit === "h" ? 3600000 : 86400000)
          );
        }

        // Validate date range
        const validationError = validateDateRange(start, end);
        if (validationError) {
          throw new Error(validationError);
        }

        interval = calculateInterval(start, end);
        // Log the time range being used
        /*console.log("Time Range:", {
          start: start.toISOString(),
          end: end.toISOString(),
          interval: interval,
        });*/
        const queryParams = new URLSearchParams({
          start: start.toISOString(),
          end: end.toISOString(),
          interval,
        });

        const response = await fetch(
          `/api/dashboard/${deviceId}/timeseriesNew?${queryParams}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Cache-Control": "no-cache",
            },
          }
        );

        if (!response.ok) {
          if (response.status === 500) {
            throw new Error("Invalid time range selected");
          }
          throw new Error(`Request failed with status ${response.status}`);
        }

        const jsonData = await response.json();
       // console.log("Fetched data:", jsonData);

        if (!jsonData || !Array.isArray(jsonData) || jsonData.length === 0) {
          setError(
            "No data available for this device in the selected time range"
          );
          // setData([]);
          return;
        }

        setData(jsonData);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error.message || "Failed to fetch device data");
        // setData([]);
      } finally {
        // setIsRefreshing(false);
      }
    },
    [deviceId, timeRange, customDateRange, startDateTime, endDateTime]
  );


  const handleZoom = (chartContext, { xaxis }) => {
    const start = new Date(xaxis.min);
    const end = new Date(xaxis.max);
    // setZoomedRange({ start, end });
    fetchData(start, end);
  };

  const handleTimeRangeChange = (value) => {
    if (value === "custom") {
      setCustomDateRange(true);
      
    } else {
      setCustomDateRange(false);
      setTimeRange(value);
  
      // setZoomedRange(null);
    }
  };

  useEffect(() => {
    fetchData();
    const interval =
      autoRefresh && !customDateRange ? setInterval(fetchData, 30000) : null;
    return () => interval && clearInterval(interval);
  }, [fetchData, autoRefresh, customDateRange]);

  const handleCheckboxChange = (metricId) => {
    setSelectedMetrics((prevMetrics) => ({
      ...prevMetrics,
      [metricId]: !prevMetrics[metricId],
    }));
  };

  const chartData = metrics
    .filter(
      (metric) =>
        selectedMetrics[metric.id] &&
        data.some((item) => item[metric.id] !== undefined)
    )
    .map((metric) => ({
      name: metric.name,
      data: data
        .map((item) => ({
          x: new Date(item.timestamp),
          y: item[metric.id] !== undefined ? item[metric.id] : null, // Fallback for undefined values
        }))
        .filter((point) => point.y !== null), // Remove points where value is null
    }));

    const handleResetZoom = useCallback((chartContext, opts) => {
      // Reset time range to 1h
      setTimeRange("1h");
      setCustomDateRange(false);
      
      const end = new Date();
      const start = new Date(end - 3600000); // 1 hour in milliseconds
      
      setEndDateTime(end.toISOString().slice(0, 16));
      setStartDateTime(start.toISOString().slice(0, 16));
      
      // Return the range we want to reset to
      return {
        xaxis: {
          min: start.getTime(),
          max: end.getTime()
        }
      };
    }, []);


    const chartOptions = {
      chart: {
        type: "line",
        zoom: {
          enabled: true,
          type: "x",
        },
        events: {
          zoomed: handleZoom,
          beforeResetZoom: handleResetZoom
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
     xaxis: {
  type: "datetime",
  labels: {
    format: "dd MMM yyyy HH:mm", // Example format
    formatter: function (value) {
      // Format the date in the local timezone
      const date = new Date(value);
      return date.toLocaleString(); // Adjusts the date to local time
    },
  },
},
      yaxis: {
        title: {
          text: "Value",
        },
        min: data.length
          ? Math.min(
              ...data.map((item) =>
                Math.min(
                  ...metrics
                    .filter((metric) => selectedMetrics[metric.id])
                    .map((metric) => item[metric.id])
                )
              )
            )
          : undefined,
        max: data.length
          ? Math.max(
              ...data.map((item) =>
                Math.max(
                  ...metrics
                    .filter((metric) => selectedMetrics[metric.id])
                    .map((metric) => item[metric.id])
                )
              )
            )
          : undefined,
      },
   tooltip: {
  shared: true,
  intersect: false,
  custom: function ({ series, seriesIndex, dataPointIndex, w }) {
    let tooltipHtml = `<div class="apexcharts-tooltip-title text-white bg-green-600 py-2 px-4 rounded-lg text-center mb-2 shadow-lg">
                          <span class="text-lg font-semibold text-blue-500">
                            ${new Date(w.globals.seriesX[seriesIndex][dataPointIndex]).toLocaleString()}
                          </span>
                        </div>`;

    metrics
      .filter((metric) => selectedMetrics[metric.id])
      .forEach((metric, idx) => {
        const value = series[idx][dataPointIndex];
        if (value !== null && value !== undefined) {
          tooltipHtml += `<div class="apexcharts-tooltip-text text-sm bg-blue-50 py-2 px-4 rounded-lg shadow-md mb-2 border-l-4" style="border-color: ${metric.color}">
                            <strong class="text-${metric.color}">${metric.name}:</strong> 
                            <span class="text-gray-800">${value}</span> ${metric.unit}
                          </div>`;
        }
      });

    return tooltipHtml;
  },
},


      legend: {
        position: "bottom",
      },
      colors: metrics
        .filter((metric) => selectedMetrics[metric.id])
        .map((metric) => metric.color),
    };
    return (
      <div>
        <div className="flex flex-col space-y-4 mb-6">
          <div className="flex justify-between items-center space-x-4 mb-5">
            {/* Time Range Selector */}
            <div className="flex space-x-2">
              {timeRanges
                .filter((range) => range.value !== 'custom')
                .map((range) => (
                  <button
                    key={range.value}
                    onClick={() => handleTimeRangeChange(range.value)}
                    className={`px-4 py-2 rounded-md text-sm font-medium transition-colors
                      ${timeRange === range.value 
                        ? 'bg-blue-500 text-white' 
                        : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                      }`}
                  >
                    {range.label}
                  </button>
                ))}
              <button
                onClick={() => handleTimeRangeChange('custom')}
                className={`px-4 py-2 rounded-md text-sm font-medium transition-colors
                  ${customDateRange 
                    ? 'bg-blue-500 text-white' 
                    : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                  }`}
              >
                Custom
              </button>
            </div>
  
            {/* Custom Date Range Inputs (conditionally rendered) */}
            {customDateRange && (
              <div className="flex gap-4 items-center">
                <input
                  type="datetime-local"
                  value={startDateTime}
                  onChange={(e) => setStartDateTime(e.target.value)}
                  className="p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
                <input
                  type="datetime-local"
                  value={endDateTime}
                  onChange={(e) => setEndDateTime(e.target.value)}
                  className="p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
                <button
                  onClick={() => fetchData()}
                  className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
                >
                  Apply
                </button>
              </div>
            )}
  
            {/* Auto-refresh Button */}
            <div>
              <button
                onClick={() => setAutoRefresh(!autoRefresh)}
                className={`flex items-center gap-2 px-3 py-1.5 rounded-md text-sm transition-colors
                  ${autoRefresh 
                    ? 'bg-green-100 text-green-700' 
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                disabled={customDateRange}
              >
                <RefreshCw className={`h-4 w-4 ${autoRefresh ? 'animate-spin' : ''}`} />
                {autoRefresh ? 'Auto-refresh ON' : 'Auto-refresh OFF'}
              </button>
            </div>
          </div>
  
          {/* Metrics Checkboxes */}
          <div className="flex flex-wrap gap-3">
          {metrics.map((metric) => (
  <label
    key={metric.id}
    className="flex items-center group cursor-pointer"
  >
    <div className="relative flex items-center">
      <input
        type="checkbox"
        id={metric.id}
        checked={selectedMetrics[metric.id]}
        onChange={() => handleCheckboxChange(metric.id)}
        className="sr-only peer"
      />
      <div className="w-5 h-5 border-2 rounded-md border-gray-300 peer-checked:bg-blue-500 peer-checked:border-blue-500 transition-colors">
        <svg
          className={`w-4 h-4 text-white absolute top-0.5 left-0.5 ${
            selectedMetrics[metric.id] ? 'opacity-100' : 'opacity-0'
          }`}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path d="M5 13l4 4L19 7" />
        </svg>
      </div>
      <span
  className="ml-2 text-sm font-bold"
  style={{ color: metric.nameColor }}
>
  {metric.name}
</span>

      <span className="ml-1 text-xs text-gray-500">({metric.unit})</span>
    </div>
  </label>
))}
          </div>
        </div>
  
        {/* Chart Section */}
        <div className="mb-10">
          {error ? (
            <div className="flex flex-col justify-center items-center my-8 mb-6 w-full">
              <div className="text-red-500 bg-red-50 px-6 py-3 rounded-md border border-red-200 text-center mb-4">
                {error}
              </div>
              <div className="w-full">
                <ApexCharts
                  options={chartOptions}
                  series={chartData}
                  type="line"
                  height={500}
                />
              </div>
            </div>
          ) : (
            <div className="w-full">
              <ApexCharts
                options={chartOptions}
                series={chartData}
                type="line"
                height={500}
              />
            </div>
          )}
        </div>
      </div>
    );
  };
  
  export default TimeSeriesChart;
  